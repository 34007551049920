<script lang="ts">
  import { goto } from "$app/navigation";
  import ConnectWalletButton from "$lib/components/ConnectWalletButton.svelte";
</script>

<svelte:head>
  <title>Vega</title>
</svelte:head>

<main class="h-screen w-screen flex justify-center items-center">
  <ConnectWalletButton on:walletConnected={() => void goto("/app/")} />
</main>
