<script lang="ts">
  import detectEthereumProviderMetamask from "@metamask/detect-provider";
  import { ethers } from "ethers";
  import { createEventDispatcher, onMount } from "svelte";
  import type {
    EthereumExternalProvider,
    MetaMaskEthereumProvider,
  } from "../ethereum/types";

  export let disabled: boolean = false;
  export let busy: boolean = false;

  const dispatch = createEventDispatcher<{
    walletConnected: ethers.providers.Web3Provider;
  }>();

  async function connectToWallet(provider: EthereumExternalProvider) {
    busy = true;
    const web3Provider = new ethers.providers.Web3Provider(provider);

    // Request permission
    try {
      const accounts: string[] = await web3Provider.send(
        "eth_requestAccounts",
        []
      );

      if (accounts.length === 0) {
        throw new Error("No accounts found.");
      }
    } catch (error) {
      if (isEipError(error) && error.code === 4001) {
        console.info("User has denied access to accounts.");
      } else {
        console.error("Unexpected error trying to request accounts.", error);
      }
      busy = false;
      return;
    }

    dispatch("walletConnected", web3Provider);
  }

  function isEipError(error: unknown): error is { code: number } {
    return (
      !!error &&
      typeof error === "object" &&
      "code" in error &&
      typeof error.code === "number"
    );
  }

  let detectedProvider: MetaMaskEthereumProvider | null = null;
  async function detectEthereumProvider() {
    busy = true;
    try {
      detectedProvider = await detectEthereumProviderMetamask();
    } catch (error) {
      console.error(error);
      detectedProvider = null;
    } finally {
      busy = false;
    }
  }

  onMount(() => {
    void detectEthereumProvider();
  });
</script>

<button
  class="bg-dark-700 text-white px-3 h-12 py-1 rounded-lg"
  type="button"
  disabled={!detectedProvider || disabled || busy}
  on:click={() => detectedProvider && connectToWallet(detectedProvider)}
>
  Connect Wallet
</button>
